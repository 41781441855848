import { parseElement as coreParseElement } from "@Core";
import Box from "@material-ui/core/Box";
import AroundMe from "../components/AroundMe";
import ReactLiftState02 from "@micado-digital/react-liftstate/ReactLiftState02";
import ReactPistState02 from "@micado-digital/react-piststate/ReactPistState02";
import ReactTourismServices from "../components/ReactTourismServices";
import ReactSnowReport01 from "@micado-digital/react-snowreport/ReactSnowReport01";
import ReservationsList from "../components/ReservationsList";
import Weather from "../components/Weather";
import Webcams from "../components/Webcams";

const parseElement = (element, lang) => {
	const { REACT_APP_PATH } = process.env;
	const { id, tag } = element;

	switch (tag) {
		case "tourism-reservations": {
			return <ReservationsList key={id} lang={lang} />;
		}
		case "tourism-services": {
			const { id, textcontent: { items = [] } = {} } = element;

			const link = items?.find(({ name }) => name === "link")?.text;

			let categories = items?.find(({ name }) => name === "categories")?.text;
			let providers = items?.find(({ name }) => name === "providers")?.text;
			let selected = items?.find(({ name }) => name === "selected")?.text;

			if (categories) categories = categories?.split("|")?.map(item => parseInt(item));
			if (providers) providers = providers?.split("|")?.map(item => parseInt(item));
			if (selected) selected = selected?.split("|")?.map(item => parseInt(item));

			return (
				<ReactTourismServices
					categories={categories}
					key={id}
					lang={lang}
					pageName={link}
					providers={providers}
					selected={selected}
					showDetailsInOverlay={true}
				/>
			);
		}
		case "mski-webcams": {
			const { id, textcontent: { items = [] } = {} } = element;

			const client = items?.find(({ name }) => name === "client")?.text;
			const region = items?.find(({ name }) => name === "region")?.text;
			const selected = items?.find(({ name }) => name === "webcams")?.text;

			return (
				<Webcams
					client={client}
					fallbackImage="/img/fallback-webcam.jpg"
					gridBreakpoints={{
						xs: 12,
						sm: 6,
						md: 4
					}}
					lang={lang}
					key={id}
					live={false}
					overlaySize="xlarge"
					region={region}
					selected={selected}
					showLocation
					skeletonItems={12}
					url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.WebcamApi/ListWebcams.api`}
				/>
			);
		}
		case "mski-liftstate": {
			const { id, textcontent: { items = [] } = {} } = element;

			const client = items?.find(({ name }) => name === "client")?.text;
			const location = items?.find(({ name }) => name === "location")?.text;
			const region = items?.find(({ name }) => name === "region")?.text;
			const season = items?.find(({ name }) => name === "season")?.text;
			const selected = items?.find(({ name }) => name === "facilities")?.text;

			return (
				<Box my={4}>
					<ReactLiftState02
						key={id}
						client={client}
						filter
						lang={lang}
						location={location}
						region={region}
						season={season}
						selected={selected}
						skeletonItems={20}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
					/>
				</Box>
			);
		}
		case "mski-piststate": {
			const { id, textcontent: { items = [] } = {} } = element;

			const client = items?.find(({ name }) => name === "client")?.text;
			const location = items?.find(({ name }) => name === "location")?.text;
			const region = items?.find(({ name }) => name === "region")?.text;
			const season = items?.find(({ name }) => name === "season")?.text;
			const selected = items?.find(({ name }) => name === "pists")?.text;

			return (
				<Box my={4}>
					<ReactPistState02
						key={id}
						client={client}
						filter
						lang={lang}
						location={location}
						region={region}
						season={season}
						selected={selected}
						skeletonItems={20}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
					/>
				</Box>
			);
		}
		case "mski-snowreport": {
			const { id, textcontent: { items = [] } = {} } = element;

			const client = items?.find(({ name }) => name === "client")?.text;
			const location = items?.find(({ name }) => name === "location")?.text;
			const region = items?.find(({ name }) => name === "region")?.text;

			return (
				<Box my={4}>
					<ReactSnowReport01
						key={id}
						client={client}
						iconSize={32}
						identifierAvalanche="avalance-level"
						identifierDateLastSnowfall="date-last-snowfall"
						identifierSnowHeightValley="snow-height-valley"
						identifierSnowHeightMountain="snow-height-mountain"
						lang={lang}
						location={location}
						region={region}
						url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.PropertyApi/ListProperties.api`}
					/>
				</Box>
			);
		}

		case "extension-weather": {
			return <Weather key={id} />;
		}

		case "extension-around-me-map": {
			const { id, textcontent: { items = [] } = {} } = element;

			const categories = items?.find(({ name }) => name === "categories")?.text;

			return <AroundMe categories={categories} key={id} />;
		}

		default: {
			return coreParseElement(element);
		}
	}
};

export default parseElement;
